body {
  font-size: 14px;
}
html {
  font-size: 100%;
}
@media (min-width: 60em) {
  html {
    font-size: 90%;
  }
}

.App {
  text-align: left;
}

.AppBar {
  text-align: left;
  background: green;
}
@media print {
  .noprint {
    display: none !important;
  }
  .enlarge {
    transform: scale(1.5);
    transform-origin: top center;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 144dpi),
  (max-width: 1024px) {
  .narrow {
    background: green;
    width: 0px !important;
    padding: 0px !important;
    /*display: none !important;*/
  }
  .InfoText {
    display: none !important;
  }
}

.xnarrow {
  background: green;
  width: 0px !important;
  padding: 0px !important;
  /*display: none !important;*/
}

.RegattaListRow {
  font-size: 1em !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.RegattaListHeaderRow {
  font-size: 1em !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  font-weight: bold !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.RegattaRow {
  height: 30px !important;
  font-size: 1em !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}

.RegattaHeaderRow {
  color: black !important;
  height: 30px !important;
  font-size: 16px !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}
.EventResults {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hide {
  display: tnone;
}
